<template>
  <div id="app">
    <dashboard />
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style src="@cyradar/ui/dist/ui.css"></style>
<style lang="scss">
.section {
  @media screen and (min-width: 1024px) {
    padding: 2rem 2rem;
  }
}

.input, .select select, .textarea {
  border-color: #aaa;
}

.is-size-65 {
  font-size: .9rem!important;
}

.title {
  margin: 0;
  padding: 0;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.boxy {
  padding: 1em;
  background-color: #fff;
  .dashboard.is-dark & {
    background-color: #1f2128;
  }
}

.text-truncate {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.label:not(:last-child) {
  margin-bottom: 0.25em;
}

.field:not(:last-child) {
  margin-bottom: 1em;
}

.column-chart {
  overflow: hidden;
}

.timepicker {
  box-shadow:inset 0px 0px 0px 1px #dbdbdb;
  border: none;

  .dashboard.is-dark & {
    box-shadow:inset 0px 0px 0px 1px #404553;
  }
}
</style>
