export const NotifierThreatFrequency = 'THREAT_FREQUENCY'
export const NotifierSensorMonitor = 'SENSOR_MONITOR'
export const ReportThreatOverview = 'THREAT_OVERVIEW'
export const ForwarderSIEM = 'FORWARDER_SIEM'
export const ThreatSeverities = ['low', 'medium', 'high', 'critical']

export default {
  state () {
    return {
      wst: null,
      version: '',
      license: {
        name: '',
        plan: '',
        api_key: '',
        expired_at: null
      },
      systemStats: {
        sensors: 0,
        bandwidth_avg: 0,
        bandwidth_peak: 0,
        traffic: 0,
        clients: 0
      }
    }
  },
  getters: {
    wst (state) {
      return state?.wst?.id
    }
  },
  mutations: {
    SET_WST (state, payload) {
      state.wst = payload
    },
    SET_VERSION (state, payload) {
      state.version = payload
    },
    SET_LICENSE (state, payload) {
      state.license = payload
    },
    SET_SYSTEM_STATS (state, payload) {
      state.systemStats = payload
    }
  },
  actions: {
    async ISSUE_WST ({ commit, dispatch }, payload) {
      const callback = payload.callback
      if (!callback || typeof callback !== 'function') {
        return
      }
      const token = await Promise.resolve(callback())
      if (!token || !token.expiration) {
        return
      }

      commit('SET_WST', token)

      const exp = token.expiration / 1e6
      window.setTimeout(() => {
        dispatch('ISSUE_WST', payload)
      }, exp)
    }
  }
}
