import { note, bug, alert, file, broadcast, person, question, rss, checklist } from 'octicons-vue'

const OrphanView = {
  render: (c) => c('router-view')
}

export default [
  {
    path: '/',
    name: 'overview',
    component: () => import(/* webpackChunkName: "overview" */ './views/Overview.vue'),
    meta: { title: 'Overview', icon: note }
  },
  {
    path: '/threats',
    name: 'threats',
    redirect: '/threats/overview',
    component: OrphanView,
    meta: { icon: bug, show: true },
    children: [{
      path: 'overview',
      name: 'threats-overview',
      component: () => import(/* webpackChunkName: "threats" */ './views/threats/Overview.vue')
    }, {
      path: 'all',
      name: 'threats-all',
      component: () => import(/* webpackChunkName: "threats" */ './views/threats/All.vue')
    }]
  },
  {
    path: '/alerts',
    name: 'alerts',
    redirect: '/alerts/overview',
    component: OrphanView,
    meta: { icon: alert, show: true },
    children: [{
      path: 'overview',
      name: 'alerts-overview',
      component: () => import(/* webpackChunkName: "alerts" */ './views/alerts/Overview.vue')
    }, {
      path: 'all',
      name: 'alerts-all',
      component: () => import(/* webpackChunkName: "alerts" */ './views/alerts/All.vue')
    }, {
      path: 'notifiers',
      name: 'alerts-notifiers',
      component: () => import(/* webpackChunkName: "alerts" */ './views/alerts/Notifiers.vue')
    }]
  },
  {
    path: '/reports',
    name: 'reports',
    redirect: '/reports/all',
    component: OrphanView,
    meta: { icon: file, show: true },
    children: [{
      path: 'all',
      name: 'reports-all',
      component: () => import(/* webpackChunkName: "reports" */ './views/reports/All.vue')
    }]
  },
  {
    path: '/sensors',
    name: 'sensors',
    redirect: '/sensors/overview',
    component: OrphanView,
    meta: { icon: broadcast, show: true },
    children: [{
      path: 'overview',
      name: 'sensors-overview',
      component: () => import(/* webpackChunkName: "sensors" */ './views/sensors/Overview.vue')
    }, {
      path: 'all',
      name: 'sensors-all',
      component: () => import(/* webpackChunkName: "sensors" */ './views/sensors/All.vue')
    }]
  },
  {
    path: '/users',
    name: 'users',
    redirect: '/users/all',
    component: OrphanView,
    meta: { icon: person, show: true },
    children: [{
      path: 'all',
      name: 'users-all',
      component: () => import(/* webpackChunkName: "users" */ './views/users/All.vue')
    }, {
      path: 'activities',
      name: 'users-activities',
      component: () => import(/* webpackChunkName: "users" */ './views/users/Activities.vue')
    }]
  },
  {
    path: '/forwarders',
    name: 'forwarders',
    redirect: '/forwarders/all',
    component: OrphanView,
    meta: { icon: rss, show: true },
    children: [{
      path: 'all',
      name: 'forwarders-all',
      component: () => import(/* webpackChunkName: "reports" */ './views/forwarders/All.vue')
    }]
  },
  {
    path: '/policies',
    name: 'policies',
    redirect: '/policies/all',
    component: OrphanView,
    meta: { icon: checklist, show: true },
    children: [{
      path: 'all',
      name: 'policies-all',
      component: () => import(/* webpackChunkName: "reports" */ './views/policies/All.vue')
    }]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    meta: { icon: question }
  }
]
