import Vue from 'vue'
import VueResizeObserver from 'vue-resize-observer'
import { UI } from '@cyradar/ui'
import { format } from 'date-fns'
import App from './App.vue'
import routes from './routes'
import store from './store'
import localization from './localization'

Vue.use(UI)
Vue.use(VueResizeObserver)

const ui = new UI({
  routes,
  store,
  auth: true,
  localization,
  http: {
    unauthenticated (ui) {
      ui.store.dispatch('LOGOUT')
    }
  },
  authAPI: () => ({
    basic ({ username, password }) {
      return ui.http.post('/api/v1/login', { email: username, password })
        .then(body => body && body.data)
    }
  })
})

Vue.config.productionTip = false

Vue.filter('dmyhs', value => {
  if (!value) {
    return value
  }

  return format(new Date(value), 'dd/MM/yyyy HH:mm:ss')
})

Vue.filter('fixed', (value, dec = 2) => {
  if (!value) {
    return value
  }

  return Number(value).toFixed(dec)
})

Vue.mixin({
  computed: {
    $user () {
      return this.$store.getters['authentication/user']
    },
    $authenticated () {
      return this.$store.getters['authentication/authenticated']
    }
  },
  methods: {
    can (permission) {
      return this.$user.can(permission)
    }
  }
})

new Vue({
  ui,
  watch: {
    $authenticated: {
      immediate: true,
      handler (v) {
        if (!v) {
          return
        }

        this.getVersion()
        this.getLicense()
        // this.getSystemStats()

        this.$store.dispatch('ISSUE_WST', {
          callback: this.issueWST
        })
      }
    }
  },
  mounted () {
    setInterval(() => {
      if (!this.$authenticated) {
        return
      }
      this.getVersion()
      this.getLicense()
      // this.getSystemStats()
    }, 30000)
  },
  methods: {
    issueWST () {
      return this.$http.post('/api/v1/users/ws')
        .then(body => {
          return body?.data?.data
        })
    },
    getVersion () {
      return this.$http.get('/api/v1/about/version')
        .then(body => body?.data)
        .then(data => {
          if (!data) {
            return
          }

          this.$store.commit('SET_VERSION', data?.data?.version)
        })
    },
    getLicense () {
      return this.$http.get('/api/v1/license')
        .then(body => body?.data)
        .then(data => {
          if (!data) {
            return
          }

          this.$store.commit('SET_LICENSE', data?.data)
        })
    },
    getSystemStats () {
      return this.$http.get('/api/v1/about/system')
        .then(body => body?.data)
        .then(data => {
          if (!data) {
            return
          }

          this.$store.commit('SET_SYSTEM_STATS', data?.data)
        })
    }
  },
  render: h => h(App)
}).$mount('#app')
