export default {
  dateTimeFormats: {
    en: {
      short: {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false
      },
      long: {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'
      }
    },
    vi: {
      short: {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false
      },
      long: {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'
      }
    }
  },
  messages: {
    en: {
      'n/a': 'Not Available',
      add: 'Add',
      save: 'Save',
      cancel: 'Cancel',
      edit: 'Edit',
      remove: 'Remove',
      delete: 'Delete',
      all: 'All',

      name: 'Name',
      title: 'Title',
      details: 'Details',
      value: 'Value',
      settings: 'Settings',
      actions: 'Actions',
      loading: 'Loading...',
      email: 'Email',
      types: 'Types',
      type: 'Type',
      token: 'Token',
      status: 'Status',
      files: 'Files',
      user: 'User',
      description: 'Description',
      endpoint: 'Endpoint',
      subnet: 'Subnet',
      hosts: 'Hosts',

      client: 'Client',
      clients: 'Clients',
      connection: 'Connection',
      source: 'Source',
      destination: 'Destination',
      severity: 'Severity',
      confidence: 'Confidence',
      hostname: 'Hostname',
      time: 'Time',
      export: 'Export File',
      matching: 'Matching',
      events: 'Events',
      threshold: 'Threshold',
      're-open': 'Reopen',
      're-opened': 'Reopened',
      resolved: 'Resolved',
      information: 'Information',
      close: 'Close',
      channels: 'Channels',
      throttle: 'Throttle',
      create: 'Create',
      next: 'Next',
      prev: 'Previous',
      any: 'Any',
      low: 'Low',
      medium: 'Medium',
      high: 'High',
      critical: 'Critical',

      inMinute: 'In a minute',
      in30Minute: 'In 30 minutes',
      inHour: 'In an hour',
      in4Hour: 'In 4 hours',
      inDay: 'In a day',
      duration30Second: 'At least 30 seconds',
      durationMinute: 'At least a minute',
      duration30Minute: 'At least 30 minutes',
      durationHour: 'At least an hour',

      fields: 'Fields',
      scopes: 'Scopes',
      password: 'Password',
      oldPassword: 'Old Password',
      confirmPassword: 'Confirm Password',
      selectDefault: 'Select one below',
      url: 'URL',
      techniques: 'Techniques',
      subtechniques: 'Sub-Techniques',
      tactics: 'Tactics',

      queryRange: 'Query Range',

      newUser: 'New User',
      newWatcher: 'New Watcher',
      newSensor: 'New Sensor',
      newReport: 'New Report',
      changePassword: 'Change Password',
      editSensor: 'Edit Sensor',
      editUser: 'Edit User',
      sensorName: 'Sensor Name',
      yourEmail: 'Your Email',

      pageOf: 'Page {0}/{1}',
      filters: 'Filters',

      license: 'License',
      systemOverview: 'System Overview',
      version: 'Version {0}',
      versionSensor: 'Version',
      plan: 'Plan',
      expiration: 'Expiration',
      bandwidth: 'Bandwidth',
      traffic: 'Traffic Analyzed',

      whitelist: 'Whitelist',
      blacklist: 'Blacklist',
      'policy-matching': 'IP/CIDR/Domain',
      newWhitelistPolicy: 'Add to Whitelist',
      newBlacklistPolicy: 'Add to Blacklist',

      addr: 'Address',
      event_format: 'Event format',
      authenticator_type: 'Authenticator Type',
      authenticator_name: 'Authenticator Name',
      authenticator_value: 'Authenticator Value',
      toggle: 'Enabled',

      route: {
        overview: 'Overview',
        threats: 'Threats',
        'threats-overview': 'Threat Overview',
        'threats-all': 'Detected Threats',
        'threats-mitre': 'MITRE ATT&CK',
        alerts: 'Alerts',
        'alerts-overview': 'Alert Overview',
        'alerts-all': 'Raised Alerts',
        'alerts-notifiers': 'Alert Settings',
        reports: 'Reports',
        'reports-all': 'Reports',
        sensors: 'Sensors',
        'sensors-overview': 'Sensor Overview',
        'sensors-all': 'Sensor Management',
        users: 'Users',
        'users-all': 'User Management',
        'users-activities': 'User Activities',
        about: 'About',
        forwarders: 'Forwarders',
        'forwarders-all': 'Forwarders',
        policies: 'Policies',
        'policies-all': 'Policies'
      },
      threats: {
        resource: 'Destination',
        search: 'Search',
        engine: 'Engine',
        'pie-severity': 'Threats By Severity',
        'bar-resource': 'Top Destinations',
        'bar-src': 'Top Infected Clients',
        'histogram-resource': 'Threats Histogram',
        'histogram-origin': 'Threats Histogram By Engine',
        'histogram-src': 'Clients Histogram'
      },
      users: {
        userCreated: 'User Created',
        userUpdated: 'User Updated',
        userDeleted: 'User Deleted'
      },
      overview: {
        'total-threats': 'Total Threats',
        'recent-threats': 'Recent Threats',
        'recent-alerts': 'Recent Alerts'
      },
      alerts: {
        latest: 'Latest Alert',
        total: 'Total Alerts',
        recent: 'Recent Alerts',
        notiFloat: 'Should be a float from 0.0 to 1.0',
        notiThreshold: 'The condition which triggers the alert when met',
        notiThrottle: 'The cooldown duration between alerts',
        betweenAlerts: 'between alerts',
        notifier: 'Notifier',
        'notifier-recipients-placeholder': 'A comma separated list of recipients',
        query: 'Query',
        'bar-notifier': 'Top Alerts',
        'histogram-notifier': 'Alerts Histogram'
      },
      reports: {
        template: 'Template',
        reportDeleted: 'Report Deleted',
        repeat: 'Repeat',
        repeat_no: 'No',
        repeat_daily: 'Daily - Last 24 hours',
        repeat_dailyRound: 'Daily - Previous day',
        repeat_weekly: 'Weekly - Last 7 days',
        repeat_weeklyRound: 'Weekly - Last week',
        repeat_monthly: 'Monthly - Last 30 days',
        repeat_monthlyRound: 'Monthly - Last month'
      },
      sensors: {
        'histogram-connection': 'Top Hosts By Connection'
      },
      chart: {
        errorNoData: 'No Data Available'
      }
    },
    vi: {
      'n/a': 'Không Khả Dụng',
      add: 'Thêm',
      save: 'Lưu',
      cancel: 'Hủy',
      edit: 'Sửa',
      remove: 'Xóa',
      delete: 'Xóa',
      all: 'Tất Cả',

      name: 'Tên',
      title: 'Tiêu Đề',
      details: 'Chi Tiết',
      value: 'Giá Trị',
      settings: 'Cài Đặt',
      actions: 'Tác Vụ',
      loading: 'Đang Tải...',
      email: 'Email',
      types: 'Các Kiểu',
      type: 'Kiểu',
      token: 'Mã Xác Thực',
      status: 'Trạng Thái',
      files: 'Tập Tin',
      user: 'Người Dùng',
      description: 'Mô Tả',
      endpoint: 'Đường Dẫn',
      subnet: 'Subnet',
      hosts: 'Máy trạm',

      client: 'Đối tượng',
      clients: 'Đối tượng',
      connection: 'Kết Nối',
      source: 'Nguồn',
      destination: 'Đích',
      severity: 'Mức Độ',
      confidence: 'Độ Tin Cậy',
      hostname: 'Tên Thiết Bị',
      time: 'Thời Gian',
      export: 'Xuất Tệp',
      matching: 'Kết Quả',
      events: 'Sự Kiện',
      threshold: 'Ngưỡng',
      're-open': 'Mở Lại',
      're-opened': 'Đã Mở Lại',
      resolved: 'Đã Xử Lý',
      information: 'Thông Tin',
      close: 'Đóng',
      channels: 'Kênh',
      throttle: 'Giới Hạn',
      create: 'Tạo',
      next: 'Tiếp',
      prev: 'Quay Lại',
      any: 'Bất Kỳ',
      low: 'Thấp',
      medium: 'Trung Bình',
      high: 'Cao',
      critical: 'Nghiêm Trọng',

      inMinute: 'Trong 1 Phút',
      in30Minute: 'Trong 30 Phút',
      inHour: 'Trong 1 Tiếng',
      in4Hour: 'Trong 4 Tiếng',
      inDay: 'Trong 1 Ngày',
      duration30Second: 'Ít Nhất 30 Giây',
      durationMinute: 'Ít Nhất 1 Phút',
      duration30Minute: 'Ít Nhất 30 Phút',
      durationHour: 'Ít Nhất 1 Tiếng',

      fields: 'Các Trường',
      scopes: 'Quyền',
      password: 'Mật Khẩu',
      oldPassword: 'Mật Khẩu Cũ',
      confirmPassword: 'Nhắc Lại Mật Khẩu',
      selectDefault: 'Vui Lòng Chọn',
      url: 'URL',
      techniques: 'Kỹ Thuật',
      subtechniques: 'Kỹ Thuật Con',
      tactics: 'Chiến Thuật',

      queryRange: 'Khoảng Thời Gian Truy Vấn',

      newUser: 'Thêm Người Dùng',
      newWatcher: 'Thêm Cảnh Báo',
      newSensor: 'Thêm Cảm Biến',
      newReport: 'Thêm Báo Cáo',
      changePassword: 'Đổi Mật Khẩu',
      editSensor: 'Sửa Cảm Biến',
      editUser: 'Sửa Người Dùng',
      sensorName: 'Tên Cảm Biến',
      yourEmail: 'Email',

      pageOf: 'Trang {0}/{1}',
      filters: 'Bộ Lọc',

      license: 'Bản Quyền Phần Mềm',
      systemOverview: 'Tổng Quan Hệ Thống',
      version: 'Phiên Bản {0}',
      versionSensor: 'Phiên Bản',
      plan: 'Gói',
      expiration: 'Hết Hạn',
      bandwidth: 'Băng Thông',
      traffic: 'Lưu Lượng Đã Xử Lý',

      whitelist: 'Danh sách cho phép',
      blacklist: 'Danh sách hạn chế',
      'policy-matching': 'IP/CIDR/Tên miền',
      newWhitelistPolicy: 'Thêm vào danh sách cho phép',
      newBlacklistPolicy: 'Thêm vào danh sách hạn chế',

      addr: 'Địa chỉ',
      event_format: 'Định dạng dữ liệu',
      authenticator_type: 'Cơ chế xác thực',
      authenticator_name: 'Trường xác thực',
      authenticator_value: 'Giá trị xác thực',
      toggle: 'Trạng thái hoạt động',

      route: {
        overview: 'Tổng Quan',
        threats: 'Mối Đe Dọa',
        'threats-overview': 'Tổng Quan',
        'threats-all': 'Đã Phát Hiện',
        'threats-mitre': 'MITRE ATT&CK',
        alerts: 'Cảnh Báo',
        'alerts-overview': 'Tổng Quan',
        'alerts-all': 'Đã Cảnh Báo',
        'alerts-notifiers': 'Cài Đặt',
        reports: 'Báo Cáo',
        'reports-all': 'Báo Cáo',
        sensors: 'Cảm Biến',
        'sensors-overview': 'Tổng Quan',
        'sensors-all': 'Quản Lý',
        users: 'Người Dùng',
        'users-all': 'Quản Lý',
        'users-activities': 'Nhật Ký Hoạt Động',
        about: 'Giới Thiệu',
        forwarders: 'Bộ chuyển tiếp',
        'forwarders-all': 'Bộ chuyển tiếp',
        policies: 'Chính sách',
        'policies-all': 'Chính sách'
      },
      threats: {
        resource: 'Điểm đến',
        search: 'Tìm kiếm',
        engine: 'Công Nghệ',
        'pie-severity': 'Mức Độ Nguy Hiểm',
        'bar-resource': 'Top Điểm Đến',
        'bar-src': 'Top Đối Tượng',
        'histogram-resource': 'Các Điểm Đến',
        'histogram-origin': 'Engine Phát Hiện',
        'histogram-src': 'Đối Tượng Ảnh Hưởng'
      },
      users: {
        userCreated: 'Tạo mới thành công',
        userUpdated: 'Sửa đổi thành công',
        userDeleted: 'Đã xóa người dùng'
      },
      teible: {
        showing: 'Hiển thị',
        total: 'trên tổng số {count} bản ghi',
        last: 'bản ghi cuối cùng',
        empty: 'Không có dữ liệu',
        filter: 'Lọc các bản ghi'
      },
      overview: {
        'total-threats': 'Tất Cả Mối Đe Dọa',
        'recent-threats': 'Điểm Đến Gần Đây',
        'recent-alerts': 'Cảnh Báo Gần Đây'
      },
      alerts: {
        latest: 'Cảnh Báo Mới Nhất',
        total: 'Toàn Bộ Cảnh Báo',
        recent: 'Cảnh Báo Gần Đây',
        notiFloat: 'Thuộc Khoảng Từ 0.0 Đến 1.0',
        notiThreshold: 'Cảnh Báo Khi Đạt Ngưỡng',
        notiThrottle: 'Thời Gian Tối Thiểu Giữa Các Cảnh Báo',
        betweenAlerts: 'Giữa Các Cảnh Báo',
        notifier: 'Tên',
        'notifier-recipients-placeholder': 'Danh sách người nhận, ngăn cách bởi dấu phẩy',
        query: 'Điều Kiện',
        'bar-notifier': 'Top Cảnh Báo',
        'histogram-notifier': 'Các Cảnh Báo Theo Thời Gian'
      },
      reports: {
        template: 'Mẫu',
        reportDeleted: 'Đã xóa báo cáo',
        repeat: 'Lặp Lại',
        repeat_no: 'Không lặp lại',
        repeat_daily: 'Hàng ngày - 24 giờ trước',
        repeat_dailyRound: 'Hàng ngày - ngày hôm trước',
        repeat_weekly: 'Hàng tuần - 7 ngày trước',
        repeat_weeklyRound: 'Hàng tuần - tuần trước',
        repeat_monthly: 'Hàng tháng - 30 ngày trước',
        repeat_monthlyRound: 'Hàng tháng - tháng trước'
      },
      sensors: {
        'histogram-connection': 'Top Người Dùng Theo Số Lượng Kết Nối'
      },
      chart: {
        errorNoData: 'Không có dữ liệu'
      }
    }
  }
}
